import React, { useEffect, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import './App.scss';

import { Container, Row, Col } from 'react-bootstrap';
import { supabase } from './supabaseClient';
import { Cards } from './components/Cards';
import { Confirmation } from './components/Confirmation';

function App() {
  const [cooldown, setCooldown] = useState(false);
  const [givenData, setData] = useState(null);

  useEffect(async () => {let { data } = await supabase.from('counters').select().order('id', { ascending: true })
  setData(data)}, {givenData})

  function displayConfirmation() {
    setCooldown(true);

    setTimeout(() => {
      setCooldown(false);
    }, 5000)
  }

  return (
    <Container>
      {cooldown && <Confirmation toggle={setCooldown} />} 
      <Row className="justify-content-md-center">
        {givenData?.map(data => (
        <Col xs lg="2" className="mb-4" key={`${data.id}`}>
          <Cards data={data} setCooldown={displayConfirmation} />
        </Col>
        ))}
      </Row>
    </Container>
  );
}

export default App;