import React from "react";
import { Card, Badge, Button, Popover, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { supabase } from '../supabaseClient';

export function Cards({ data, setCooldown }) {
    async function updateCounter() {
        const { error } = await supabase
        .from('counters')
        .update({ counterAmount: data.counterAmount + 1 })
        .eq('id', data.id)
        setTimeout(() => {
            window.location.reload()
        }, 5000)
    }

    async function decreaseCounter() {
        const { error } = await supabase
        .from('counters')
        .update({ counterAmount: data.counterAmount - 1})
        .eq('id', data.id)
        setTimeout(() => {
            window.location.reload()
        }, 5000)
    }

    const popover = (
        <Popover id="popover-basic">
          <Popover.Header as="h3">Informatie</Popover.Header>
          <Popover.Body>
            Deze teller is gestart op <strong>{data.startedAt}</strong>.
          </Popover.Body>
        </Popover>
      );

    return (
        <Card className="h-100 shadow-sm bg-dark rounded">
            <Card.Img variant="top" src={data.image}/>
            <Card.Body className="d-flex flex-column">
                <div className="d-flex mb-2 justify-content-between">
                    <Card.Title className="mb-0 text-white font-weight-bold">{data.counterName}</Card.Title>
                    <Badge pill className="mb-1" bg="primary">
                        {data.counterAmount}x
                    </Badge>
                </div>
                <Card.Text className="text-secondary">{data.counterDescription}</Card.Text>
                <Button
                    onClick={() => {
                        setCooldown();
                        updateCounter();
                    }}
                    className="mt-auto font-weight-bold"
                    variant="success"
                    block
                >
                    ➕
                </Button>
                <Button
                    // onClick={() => {
                    //     setCooldown();
                    //     decreaseCounter();
                    // }}
                    className="mt-auto font-weight-bold"
                    variant="warning"
                    style={{ pointerEvents: 'none' }}
                    block
                    disabled
                >
                    ➖
                </Button>
                <OverlayTrigger trigger="click" placement="bottom" overlay={popover}>
                    <Button variant="primary">ℹ️</Button>
                </OverlayTrigger>
            </Card.Body>
        </Card>
    )
}