import React from "react";
import { Toast } from 'react-bootstrap'; 

export function Confirmation({ toggle }) {
    return (
        <Toast onClose={() => toggle(false)}>
            <Toast.Header>
                <strong className="mr-auto">Je hebt een counter geupdate!</strong>
                <small>just now</small>
            </Toast.Header>
            <Toast.Body>
                Deze pagina wordt over 5 seconden herladen!
            </Toast.Body>
        </Toast>
    );
}